import { lazy } from "react";
// project imports
import MainLayout, { MainLayoutV2 } from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AuthGuard from "../utils/route-guard/AuthGuard";
import AuthGuardV2 from "../utils/route-guard/AuthGuardV2";

const Dashboard = Loadable(
  lazy(() => import("../views/dashboard-admin/Dashboard"))
);
const AccountPage = Loadable(
  lazy(() => import("../views/dashboard-admin/AccountPage"))
);
const BannerPage = Loadable(
  lazy(() => import("../views/dashboard-admin/BannerPage"))
);
const ContactPage = Loadable(
  lazy(() => import("../views/dashboard-admin/ContactPage"))
);
const SupportPage = Loadable(
  lazy(() => import("../views/dashboard-admin/SupportPage"))
);
const AuthPage = Loadable(
  lazy(() => import("../views/dashboard-admin/AuthPage"))
);

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/account",
      element: <AccountPage />,
    },
    {
      path: "/banners",
      element: <BannerPage />,
    },
    {
      path: "/contact",
      element: <ContactPage />,
    },
    {
      path: "/support",
      element: <SupportPage />,
    },
    {
      path: "/auth",
      element: <AuthPage />,
    },
  ],
};

export const MainRoutesV2 = {
  path: "/",
  element: (
    <AuthGuardV2>
      <MainLayoutV2 />
    </AuthGuardV2>
  ),
  children: [],
};
export default MainRoutes;
