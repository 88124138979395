export const AUTH_API = {
    SignIn: 'auth/login'
};

export const USER_API = {
    CreateUser: 'users',
    DeleteUser: 'users',
    User: 'users',
    UserImport: 'users/excel',
    Group: 'group',
    LoanSlip: 'loan-slip',
    Supplies: 'supplies',
    SuppliesImport: 'supplies/excel',
    SuppliesV2: 'supplies/qty',
    Category: 'category',
    Author: 'authors',
    Publisher: 'publisher',
    Cabinet: 'cabinet',
    PrintedMatter: 'printed-matter',
    StateOfBook: 'state-of-book',
    TypeOfSupplies: 'type-of-supplies',
    TypeOfSupplieslt: 'typeOfSupplies',
    AssetLib: 'properties',
    Contact: 'contact',
    ContactImg: 'contact/image',
};

export const PROJECT = {
    NoneOfNft: 'project/none-nft',
    Detail: 'project/detail/'
};

export const NFT = {
    Issue: 'nft/issueNft',
    Detail: 'nft/detail/',
    Extend: 'nft/extend/',
    UpdateRating: 'nft/'
}
