import { FormattedMessage } from 'react-intl';
import { IconArticle } from '@tabler/icons';
import { VERSION } from '../config';

// constant
const icons = { IconArticle };


// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboardAdmin = {
    id: 'dashboard-admin',
    title: `Version Admin`,
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: <FormattedMessage id="dashboard" />,
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconArticle,
        //     breadcrumbs: false
        // },
        {
            id: 'account',
            title: <FormattedMessage id="account" />,
            type: 'item',
            url: '/account',
            icon: icons.IconArticle,
            breadcrumbs: false
        },
        {
            id: 'auth',
            title: <FormattedMessage id="auth" />,
            type: 'item',
            url: '/auth',
            icon: icons.IconArticle,
            breadcrumbs: false
        },
        // {
        //     id: 'banners',
        //     title: <FormattedMessage id="banners" />,
        //     type: 'item',
        //     url: '/banners',
        //     icon: icons.IconArticle,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'item',
        //     url: '/contact',
        //     icon: icons.IconArticle,
        //     breadcrumbs: false
        // },
    ]
};

export const dashboardAdminV2 = {
    id: 'dashboard-admin',
    title: `Version ${VERSION}`,
    type: 'group',
    children: []
};


export default dashboardAdmin;
