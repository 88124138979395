import { useNavigate } from 'react-router-dom';

// project imports
import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { GuardProps } from '../../types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuardV2 = ({ children }: GuardProps) => {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const apiLT = localStorage.getItem("serviceTokenLT");
    const isLogin = true

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
        const delayTimeout = setTimeout(() => {
            if (!isLogin) {
                navigate('lien-thong');
            }
        }, 300);

        return () => clearTimeout(delayTimeout);
    }, [navigate, isLoggedIn, isLogin]);

    return children;
};

export default AuthGuardV2;
