import React from 'react';
import { createRoot } from 'react-dom/client';

// Third-party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Project imports
import App from './App';
import { BASE_PATH } from './config';
import { ConfigProvider } from './contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { persister, store } from './store';

// Style + assets
import './assets/scss/style.scss';

// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.REACT_APP_DNS_SENTRY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

// ==============================|| REACT DOM RENDER  ||============================== //

const root = createRoot(document.getElementById('root')); // Create a root.
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
